import React, { useEffect, useRef, useState } from 'react'
import Share from '../../Images/share.svg'
import Download from '../../Images/print.svg'
import Person from '../../Images/p1.jpg'
import Person2 from '../../Images/p2.jpg'
import Person3 from '../../Images/p3.jpg'
import Person4 from '../../Images/p4.jpg'
import { PieChart, Pie,Label, Cell } from 'recharts';
import { useParams } from 'react-router-dom'
import getServices from '../../Services/get-services'
import Loading from '../SelectValue/Loading'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Divider } from '@mui/material'

function ReportsDetials() {
    const [loading, setLoading] = useState(false)
    const { id, name } = useParams();
    const[result, setResult] = useState({})
    useEffect(() =>{
        setLoading(true)
        getServices.getSingleAssesstment(id).then(
            res =>{
               setLoading(false)
               if(res.data.statusCode === '200'){
                   setResult(res.data.result)
               }
            }   
           ).catch(
               error =>{
                   console.log(error)
               }
           )
    },[id])
    const percentageScore = result?.skillGroupData?.[0]?.percentage_score;
    const percentageScore2 = result?.skillGroupData?.[1]?.percentage_score;
    const percentageScore3 = result?.skillGroupData?.[2]?.percentage_score;
    const percentageScoreInt = percentageScore ? parseInt(percentageScore) : 0;
    const percentageScoreInt2 = percentageScore2 ? parseInt(percentageScore2) : 0;
    const percentageScoreInt3 = percentageScore3 ? parseInt(percentageScore3) : 0;
    const data = [
        { name: 'Data 2', value: percentageScoreInt },
        { name: 'Data 1', value: 100 - percentageScoreInt  },
      ];
      const data2 = [
        { name: 'Data 2', value: percentageScoreInt2 },
        { name: 'Data 1', value: 100 - percentageScoreInt2  },
        ];
        const data3 = [
            { name: 'Data 2', value: percentageScoreInt3 },
            { name: 'Data 1', value: 100 - percentageScoreInt3  },
        ];
      
      const COLORS = ['#000000', '#D9D9D9'];
      const groupValues = (questions, componentName) => {
        if (!questions || !Array.isArray(questions)) {
            return 0; // Return default value if questions is undefined or not an array
        }
    
        const filteredQuestions = questions.filter(question => question.component_name === componentName);
        if (filteredQuestions.length === 0) {
            return 0; // Return default value if no questions match the component name
        }
    
        const sum = filteredQuestions.reduce((total, question) => total + parseInt(question.answer), 0);
        const average = filteredQuestions.length > 1 ? sum / filteredQuestions.length : sum;
    
        return Math.round(average);
    };
      
      const sumOne1 = groupValues(result?.questionData, "Prior Knowledge");
      const sumOne2 = groupValues(result?.questionData, "Social Engagement");
      const sumOne3 = groupValues(result?.questionData, "Relevance");
      const sumOne4 = groupValues(result?.questionData, "Traditional Lecture");
      const sumOne5 = groupValues(result?.questionData, "Metacognition");
      const sumOne6 = groupValues(result?.questionData, "Empowerment");
      const sumOne7 = groupValues(result?.questionData, "Usefulness");
      const sumOne8 = groupValues(result?.questionData, "Success");
      const sumOne9 = groupValues(result?.questionData, "Interest");
      const sumOne10 = groupValues(result?.questionData, "Caring");
      const sumOne11 = groupValues(result?.questionData, "Meaningful Application");
      const sumOne12 = groupValues(result?.questionData, "Organization");
      const sumOne13 = groupValues(result?.questionData, "Visualization");
      const sumOne14 = groupValues(result?.questionData, "Elaboration");
      const sumOne15= groupValues(result?.questionData, "Repetition");
      const  time = (result?.inserted_dt ?? "").slice(-8);
      const date = (result?.inserted_dt ?? "").slice(0,-8);
    console.log(sumOne8)
      const groupQuestions = (questionData) => {
        // Object to store the sum and count for each component_name
        const groups = {};
    
        // Iterate through the questionData array
        questionData?.forEach(question => {
            const { component_name, answer, group_name } = question;
    
            // Initialize the group if not exists
            if (!groups[component_name]) {
                groups[component_name] = { sum: 0, count: 0, average: 0, group: group_name };
            }
    
            // Add the answer to the sum and increment the count
            groups[component_name].sum += parseInt(answer);
            groups[component_name].count++;
        });
    
        // Calculate the average for each group
        for (const key in groups) {
            groups[key].average = groups[key].sum / groups[key].count;
            // Round up the average score
            groups[key].average = Math.ceil(groups[key].average);
        }
    
        // Sort the groups based on the average score
        const sortedGroups = Object.entries(groups).sort(([, a], [, b]) => a.average - b.average);
    
        // Initialize an array to store the last 7 component names
        const last7Components = [];
    
        // Iterate through the sorted groups and find the last 7 component names
        let count = 0;
        for (let i = 0; i < sortedGroups.length && count < 7; i++) {
            const [component_name, { average, group }] = sortedGroups[i];
    
            // Extract the numeric part from the component name
            const groupNumber = parseInt(component_name.split(" ")[1]);
    
            // Check if the component_name is not group 3 or 4
            if (groupNumber !== 3 && groupNumber !== 4) {
                last7Components.push({ component_name, group, average });
                count++;
            }
        }
    
        return last7Components;
    };
   
    // Usage
    const last7ComponentNames = groupQuestions(result?.questionData);
    console.log(last7ComponentNames);

    const lectureIndex1 = last7ComponentNames?.findIndex(item => item.component_name === "Prior Knowledge");
    const lectureIndex2 = last7ComponentNames?.findIndex(item => item.component_name === "Social Engagement");
    const lectureIndex3 = last7ComponentNames?.findIndex(item => item.component_name === "Relevance");
    const lectureIndex4 = last7ComponentNames?.findIndex(item => item.component_name === "Traditional Lecture");
    const lectureIndex5 = last7ComponentNames?.findIndex(item => item.component_name === "Metacognition");
    const lectureIndex6 = last7ComponentNames?.findIndex(item => item.component_name === "Empowerment");
    const lectureIndex7 = last7ComponentNames?.findIndex(item => item.component_name === "Usefulness");
    const lectureIndex8 = last7ComponentNames?.findIndex(item => item.component_name === "Success");
    const lectureIndex9 = last7ComponentNames?.findIndex(item => item.component_name === "Interest");
    const lectureIndex10 = last7ComponentNames?.findIndex(item => item.component_name === "Caring");
    const lectureIndex11 = last7ComponentNames?.findIndex(item => item.component_name === "Meaningful Application");
    const lectureIndex12 = last7ComponentNames?.findIndex(item => item.component_name === "Organization");
    const lectureIndex13 = last7ComponentNames?.findIndex(item => item.component_name === "Visualization");
    const lectureIndex14 = last7ComponentNames?.findIndex(item => item.component_name === "Elaboration");
    const lectureIndex15 = last7ComponentNames?.findIndex(item => item.component_name === "Repetition");

    const pdfRef = useRef()
    const downloadPdf = () => {
        const input = pdfRef.current;
        
        // Get the dimensions of the input element
        const inputWidth = input.clientWidth;
        const inputHeight = input.clientHeight;
      
        // Set options for html2canvas to capture entire content
        const options = {
          scrollY: -window.scrollY, // Set scrollY to current scroll position to capture entire content
          width: inputWidth,
          height: inputHeight,
        };
      
        // Initialize a new jsPDF instance
        const pdf = new jsPDF('p', 'px', [inputWidth, inputHeight], true);
      
        // Function to add a page to the PDF
        const addPageToPdf = (canvas, pageNumber) => {
          if (pageNumber > 1) {
            pdf.addPage([inputWidth, inputHeight]); // Add a new page to the PDF
          }
          const pdfPageHeight = pdf.internal.pageSize.height;
          const pdfPageWidth = pdf.internal.pageSize.width;
      
          // Calculate scaling factor to fit the canvas on the PDF page
          const scale = Math.min(pdfPageWidth / canvas.width, pdfPageHeight / canvas.height);
            // Add a rectangle with the desired background color to the PDF
            pdf.setFillColor('#F0F2F5'); // Set background color (red in this example)
            pdf.rect(0, 0, pdfPageWidth, pdfPageHeight, 'F'); // Draw filled rectangle
          // Calculate position to center the canvas on the PDF page
          const x = (pdfPageWidth - (canvas.width * scale)) / 2;
          const y = (pdfPageHeight - (canvas.height * scale)) / 2;
      
          // Add the canvas to the PDF
          pdf.addImage(canvas, 'PNG', x, y, canvas.width * scale, canvas.height * scale);
      
          return pdf;
        };
      
        // Capture each page and add it to the PDF
        html2canvas(input, options)
          .then((canvas) => {
            addPageToPdf(canvas, 1); // Add the first page
            return canvas;
          })
          .then((firstPageCanvas) => {
            // Loop through the rest of the pages
            let pageNumber = 2;
            let yOffset = inputHeight;
            while (yOffset < input.scrollHeight) {
              const nextPageOptions = {
                ...options,
                scrollY: yOffset - window.scrollY, // Set scrollY to capture next page
              };
              return html2canvas(input, nextPageOptions)
                .then((canvas) => {
                  addPageToPdf(canvas, pageNumber); // Add the next page
                  yOffset += inputHeight; // Increment yOffset for the next page
                  pageNumber++;
                  return canvas;
                });
            }
          })
          .then(() => {
            // Save the PDF
            pdf.save('lecturesoftreport.pdf');
          })
          .catch((error) => {
            console.error('Error generating PDF:', error);
          });
      };
      const captureScreen = async (element) => {
        try {
          const canvas = await html2canvas(element);
          return canvas;
        } catch (error) {
          console.error('Error capturing screen:', error);
          throw error;
        }
      };

      const generatePDF = (canvas, filename = 'document.pdf') => {
        const pdf = new jsPDF();
        pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0);
        pdf.save(filename);
      };
      const handleDownloadPDF = async () => {
        try {
          const canvas = await captureScreen(pdfRef.current);
          generatePDF(canvas);
        } catch (error) {
          console.error('Error generating PDF:', error);
        }
      };
      const strenght = [
        { value: ' Prior Knowledge', condition: sumOne1 === 3 || sumOne1 === 4 },
        { value: ' Social engagement', condition: sumOne2 === 3 || sumOne2 === 4 },
        { value: ' Relevance', condition: sumOne3 === 3 || sumOne3 === 4 },
        { value: ' Traditional Lecture', condition: sumOne4 === 3 || sumOne4 === 4 },
        { value: ' Metacognition', condition: sumOne5 === 3 || sumOne5 === 4 },
        { value: ' Empowerment', condition: sumOne6 === 3 || sumOne6 === 4 },
        { value: ' Usefulness', condition: sumOne7 === 3 || sumOne7 === 4 },
        { value: ' Success', condition: sumOne8 === 3 || sumOne8 === 4 },
        { value: ' Interest', condition: sumOne9 === 3 || sumOne9 === 4 },
        { value: ' Caring', condition: sumOne10 === 3 || sumOne10 === 4 },
        { value: ' Meaningful Application', condition: sumOne11 === 3 || sumOne11 === 4 },
        { value: ' Organization', condition: sumOne12 === 3 || sumOne12 === 4 },
        { value: ' Visualization', condition: sumOne13 === 3 || sumOne13 === 4 },
        { value: ' Elaboration', condition: sumOne14 === 3 || sumOne14 === 4 },
        { value: ' Repetition', condition: sumOne15 === 3 || sumOne15 === 4 }
      ]
      const potential =   [
        { value: ' Prior Knowledge', condition: sumOne1 === 2 },
        { value: ' Social engagement', condition: sumOne2 === 2  },
        { value: ' Relevance', condition: sumOne3 === 2 },
        { value: ' Traditional Lecture', condition: sumOne4 === 2 },
        { value: ' Metacognition', condition: sumOne5 === 2  },
        { value: ' Empowerment', condition: sumOne6 === 2 },
        { value: ' Usefulness', condition: sumOne7 === 2 },
        { value: ' Success', condition: sumOne8 === 2 },
        { value: ' Interest', condition: sumOne9 === 2 },
        { value: ' Caring', condition: sumOne10 === 2 },
        { value: ' Meaningful Application', condition: sumOne11 === 2  },
        { value: ' Organization', condition: sumOne12 === 2 },
        { value: ' Visualization', condition: sumOne13 === 2  },
        { value: ' Elaboration', condition: sumOne14 === 2  },
        { value: ' Repetition', condition: sumOne15 === 2 }
      ]

      const lowPotential = [
        { value: ' Prior Knowledge', condition: sumOne1 === 1 },
        { value: ' Social engagement', condition: sumOne2 === 1  },
        { value: ' Relevance', condition: sumOne3 === 1 },
        { value: ' Traditional Lecture', condition: sumOne4 === 1 },
        { value: ' Metacognition', condition: sumOne5 === 1  },
        { value: ' Empowerment', condition: sumOne6 === 1 },
        { value: ' Usefulness', condition: sumOne7 === 1 },
        { value: ' Success', condition: sumOne8 === 1 },
        { value: ' Interest', condition: sumOne9 === 1 },
        { value: ' Caring', condition: sumOne10 === 1 },
        { value: ' Meaningful Application', condition: sumOne11 === 1  },
        { value: ' Organization', condition: sumOne12 === 1 },
        { value: ' Visualization', condition: sumOne13 === 1  },
        { value: ' Elaboration', condition: sumOne14 === 1  },
        { value: ' Repetition', condition: sumOne15 === 1 }
      ]
      const weak = [
        { value: ' Prior Knowledge', condition: sumOne1 === 0 },
        { value: ' Social engagement', condition: sumOne2 === 0  },
        { value: ' Relevance', condition: sumOne3 === 0 },
        { value: ' Traditional Lecture', condition: sumOne4 === 0 },
        { value: ' Metacognition', condition: sumOne5 === 0  },
        { value: ' Empowerment', condition: sumOne6 === 0 },
        { value: ' Usefulness', condition: sumOne7 === 0 },
        { value: ' Success', condition: sumOne8 === 0 },
        { value: ' Interest', condition: sumOne9 === 0 },
        { value: ' Caring', condition: sumOne10 === 0 },
        { value: ' Meaningful Application', condition: sumOne11 === 0  },
        { value: ' Organization', condition: sumOne12 === 0 },
        { value: ' Visualization', condition: sumOne13 === 0  },
        { value: ' Elaboration', condition: sumOne14 === 0  },
        { value: ' Repetition', condition: sumOne15 === 0 }
      ]
  return (
    <div ref={pdfRef}>
            <Loading
                open={loading}
            />
        <div className=' bg-white px-10 py-5 mt-5 mb-10 rounded-xl flex justify-between'>
         <p className=' font-medium pt-3 text-[16px]'>{name} - {result?.title} - {result?.inserted_dt} </p>
         <div className=' flex gap-4'>
            {/* <img src={Share} alt='Share'/> */}
            <div >
            <img onClick={downloadPdf} src={Download} alt='download'/>
            </div>
           
         </div>
        </div>
        <div className=' bg-white p-10 my-5 rounded-xl grid grid-cols-2 md:grid-cols-5 gap-4'>
        <div>
       
            <p className=' text-[#2E3CC2] font-bold text-[16px] mb-2'>Assessment Name:</p>
            <p className='text-[#14013A] font-medium text-[16px] mb-5'>{result?.title}</p>
            <p className=' text-[#2E3CC2] font-bold text-[16px] mb-2'>Name:</p>
            <p className='text-[#14013A] font-medium text-[16px] mb-3'>{name} </p>
        </div>
        <div>
            <p className=' text-[#2E3CC2] font-bold text-[16px] mb-2'>Time:</p>
            <p className='text-[#14013A] font-medium text-[16px] mb-5'>{time}</p>
            <p className=' text-[#2E3CC2] font-bold text-[16px] mb-2'>Date:</p>
            <p className='text-[#14013A] font-medium text-[16px] mb-3'>{date} </p>
        </div>
       
        </div>
        <div className='  grid grid-cols-1 sm:grid-cols-3 gap-6 my-5 '>
        <div className=' bg-white p-10 my-5 flex justify-center  rounded-xl'>
        <div>
        <p className=' text-[16px] font-[500] mx-auto w-[80%] text-center'>Lecture soft 21st century teaching score</p>
            <p className=' text-[70px] font-bold text-[#2E3CC2] text-center'>{result?.score_percentage === null ? 0 : Math.ceil(result?.score_percentage ?? 0)}%</p>
        </div>
            
        </div>
        <div className='bg-white p-10 px-24 my-5 rounded-xl'>
            <p className=' text-[16px] font-[500] mb-5 text-center'>Your teacher persona is</p>
            <img src={result?.score_percentage >= 60 && result?.score_percentage <= 85 ? Person:result?.teacher_personal ==='Star Teacher'? Person4:result?.teacher_personal ==='The Drill Sergeant'? Person3 :result?.teacher_personal ==='Celebrity Teacher'? Person :result?.teacher_personal ==='The Despot'?Person2:Person2  } height={150} width={150} alt='person' className=' mb-5 mx-auto'/>
            <p className=' text-[16px] font-[500] mb-5 text-center'>{result?.score_percentage >= 60 && result?.score_percentage <= 85 ? 'Celebrity Teacher':result?.teacher_personal}</p>
        </div>
        <div className='bg-white p-10 my-5  flex justify-center  rounded-xl'>
        <div className=' '>
        <p className=' text-[16px] font-[500] w-[80%] mx-auto mb-3 text-center'>Overall, your 21st century teaching skills are</p>
        <div className=' flex justify-center'>
        <p className= { ' bg-[#08081A26] inline-block text-[#000F2C] font-medium text-[16px] px-4 py-3 rounded-lg' } >{result?.teaching_skill}</p>
        </div>
        </div>
        </div>
           
        </div>
        <div className=' bg-white p-10 mb-10 my-5 rounded-xl'>
            <p className=' font-[700] text-[18px] text-[#14013A] mb-5'>Your score breakdown</p>
            <div className=' grid sm:grid-cols-2 md:grid-cols-3 gap-4'>
            <div className="flex flex-col items-center justify-center">
                <div>
                  <PieChart width={400} height={200}>
                    <Pie
                      data={data}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={80}
                      fill="#8884d8"
                      paddingAngle={5}
                      dataKey="value"
                    >
                      {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                      <Label value={`${percentageScoreInt}%`} position="center" />
                    </Pie>
                  </PieChart>
                </div>
                <p className="text-center text-[16px] font-bold text-[#14013A]">Learner centricity</p>
              </div>

            <div className="flex flex-col items-center justify-center">
            <PieChart width={400} height={200}>
                <Pie
                data={data2}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={3}
                dataKey="value"
                    >
            {data2.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
            <Label value={`${percentageScoreInt2}%`} position="center" />
            </Pie>
           </PieChart>
           <p className="text-center text-[16px] font-bold text-[#14013A]">Teaching for recall</p>
            </div>
            <div  className="flex flex-col items-center justify-center">
            <PieChart width={400} height={200}>
                <Pie
                data={data3}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
                    >
            {data3.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
            <Label value={`${percentageScoreInt3}%`} position="center" />
            </Pie>
           </PieChart>
           <p className="text-center text-[16px] font-bold text-[#14013A]">Learner Engagement</p>
            </div>
            </div>
            
        </div>
        <div className=' bg-white p-10 mb-10 my-5 rounded-xl'>
            <p className=' font-[700] text-[18px]  text-[#14013A] mb-7'>Your Professional Development Summary</p>
            <div className=' '>
             <div className=' grid sm:grid-cols-2 gap-4'>
                <div className=' flex gap-4'>
                
                 <div>
                    <h3 className=' font-bold text-[#2E3CC2] text-[16px] mb-3'>Strengths</h3>
                    <p className=' font-[500] text-[16px] mb-3'>
                    {strenght.filter(item => item.condition) // Filter items with the condition
                          .map((item, index, array) => (
                            <React.Fragment key={index}>
                              {item.value}
                              {index < array.length - 1 && ','} {/* Add comma if not the last item */}
                            </React.Fragment>
                          ))}
                    </p>
                 </div>
                </div>
                <div className=' flex gap-4'>
               
                 <div>
                    <h3 className=' font-bold text-[#2E3CC2] text-[16px] mb-3'>Potential</h3>
                    <p className=' font-[500] text-[16px] mb-3'>
                    {potential.filter(item => item.condition) // Filter items with the condition
                      .map((item, index, array) => (
                        <React.Fragment key={index}>
                          {item.value}
                          {index < array.length - 1 && ','} {/* Add comma if not the last item */}
                        </React.Fragment>
                      ))}
                    </p>
                 </div>
                </div>
                <div className=' flex gap-4'>
                 
                 <div>
                    <h3 className=' font-bold text-[#2E3CC2] text-[16px] mb-3'>Low Potential</h3>
                    <p className=' font-[500] text-[16px] mb-3'>
                    {lowPotential.filter(item => item.condition) // Filter items with the condition
                    .map((item, index, array) => (
                      <React.Fragment key={index}>
                        {item.value}
                        {index < array.length - 1 && ','} {/* Add comma if not the last item */}
                      </React.Fragment>
                    ))}
                    </p>
                 </div>
                </div>
                <div className=' flex gap-4'>
              
                 <div>
                    <h3 className=' font-bold text-[#2E3CC2] text-[16px] mb-3'>weaknesses</h3>
                    <p className=' font-[500] text-[16px] mb-3'>
                    {weak.filter(item => item.condition) // Filter items with the condition
                        .map((item, index, array) => (
                          <React.Fragment key={index}>
                            {item.value}
                            {index < array.length - 1 && ','} {/* Add comma if not the last item */}
                          </React.Fragment>
                        ))}
                    </p>
                 </div>
                </div>
             </div>
            </div>

        </div>
        <div className=' bg-white p-10 my-5 rounded-xl'>
            <p className=' font-[700] text-[18px] text-[#14013A] mb-5'>Your Custom Guidance</p>
            {lectureIndex1 !== -1 ? <div>
                <div className=' my-6'>
                <p className=' text-[20px] font-[700] text-[#2E3CC2] mb-2'>{last7ComponentNames[lectureIndex1].component_name}</p>
                <p className=''>Your score in {last7ComponentNames[lectureIndex1].component_name} indicates that {last7ComponentNames[lectureIndex1].average === 0 ? 
                ' you have little to no skill in this area': last7ComponentNames[lectureIndex1].average === 1 ?
                ' there is much room for improvement': last7ComponentNames[lectureIndex1].average === 2 ? ' there is room for improvement':
                last7ComponentNames[lectureIndex1].average === 3 ?' you have basic competence in this area ': last7ComponentNames[lectureIndex1].average === 4
                 ? ' you have mastery of this competency': null}. This skill involves requesting for prior knowledge in the topic or area to be taught before beginning a lesson topic.
                   <br></br>Asking learners to recall and report their prior knowledge on a topic before you teach it does many things for the learners:</p>
                   <ul className="list-disc">
                    <li className=' ml-6'>Clarifies what area the student is about to delve into, anchoring the learning in their minds.</li>
                    <li className=' ml-6'>Prepares the brain to expand expertise in an area by recalling their existing competence.</li>
                    <li className=' ml-6'>Students who had no prior knowledge gain some baseline pre-info through hearing their peers’ summaries.</li>
                    <li className=' ml-6'>Prevents the teacher from using terms and concepts that are still unfamiliar to the students by checking their level on a topic.</li>
                    <li className=' ml-6'>Improves student engagement because the interest the teacher has taken in their starting state.</li>
                   </ul>
                   <p>You can build in exercises and activity to activate prior knowledge before each lesson such as by unmarked quizzes, or just live polling the class or asking questions.</p>
            </div>
            <Divider></Divider>
            </div>: null}
           
            {lectureIndex2 !== -1 ? <div className=' my-6'>
                <p className=' text-[20px] font-[700] text-[#2E3CC2] mb-2'>{last7ComponentNames[lectureIndex2].component_name}</p>
                <p className=''>Your score in {last7ComponentNames[lectureIndex2].component_name} indicates that {last7ComponentNames[lectureIndex2].average === 0 ? 
                ' you have little to no skill in this area': last7ComponentNames[lectureIndex2].average === 1 ?
                ' there is much room for improvement': last7ComponentNames[lectureIndex2].average === 2 ? ' there is room for improvement':
                last7ComponentNames[lectureIndex2].average === 3 ?' you have basic competence in this area ': last7ComponentNames[lectureIndex2].average === 4
                 ? ' you have mastery of this competency': null}. This skill involves creating an interactive learning community with and among your students.
                   <br></br>Learning communities boost learning and recall through the social nature of human learning.</p>
                   <ul className="list-disc">
                    <li className=' ml-6'>Having learners interact with each other keeps them engaged and awake.</li>
                    <li className=' ml-6'>Just knowing that your opinions will be solicited and heard improves all learners’ motivation inside and outside of school.</li>
                    
                   </ul>
                   <p className=' mb-3'>Improved engagement increases encoding and makes students learn and recall material.</p>
                   <Divider></Divider>
            </div>: null}
           
            {lectureIndex3 !== -1 ? <div className=' my-6'>
                <p className=' text-[20px] font-[700] text-[#2E3CC2] mb-2'>{last7ComponentNames[lectureIndex3].component_name}</p>
                <p className=''>Your score in {last7ComponentNames[lectureIndex3].component_name} indicates that {last7ComponentNames[lectureIndex3].average === 0 ? 
                ' you have little to no skill in this area': last7ComponentNames[lectureIndex3].average === 1 ?
                ' there is much room for improvement': last7ComponentNames[lectureIndex3].average === 2 ? ' there is room for improvement':
                last7ComponentNames[lectureIndex3].average === 3 ?' you have basic competence in this area ': last7ComponentNames[lectureIndex3].average === 4
                 ? ' you have mastery of this competency': null}. This skill involves giving students applied, relatable ways in which course concepts are used in real life.
                   <br></br>For powerful outcomes, always approach lessons from the standpoint of what the lesson information applies to in life.</p>
                   <ul className="list-disc">
                    <li className=' ml-6'>All lessons should be consistently tied to their practical application in the world of work to increase learning.</li>
                    <li className=' ml-6'>Real things are easier for the brain to hold on to than abstract concepts.</li>
                    
                   </ul>
                   <p className=' mb-3'>Knowing the students intended field of work will allow you to make this even more relevant and real to individual students.</p>
                   <Divider></Divider>
            </div>: null}
            {lectureIndex4 !== -1 ? <div className=' my-6'>
                <p className=' text-[20px] font-[700] text-[#2E3CC2] mb-2'>{last7ComponentNames[lectureIndex4].component_name}</p>
                <p className=''>Your score in {last7ComponentNames[lectureIndex4].component_name} indicates that {last7ComponentNames[lectureIndex4].average === 0 ? 
                ' you have little to no skill in this area': last7ComponentNames[lectureIndex4].average === 1 ?
                ' there is much room for improvement': last7ComponentNames[lectureIndex4].average === 2 ? ' there is room for improvement':
                last7ComponentNames[lectureIndex4].average === 3 ?' you have basic competence in this area ': last7ComponentNames[lectureIndex4].average === 4
                 ? ' you have mastery of this competency': null}. This skill involves the amount of time students spend in passive sit-and-get instructional activity.
                   <br></br>The person actively engaging the material learns the most. If that is the teacher or lecturer (not the learner) then we are doing the learners a disservice.</p>
                   <ul className="list-disc">
                    <li className=' ml-6'>Lecture is a passive form of teaching and is the least effective way of imparting knowledge.</li>
                    <li className=' ml-6'>Lecture leads to sit-and-get learning environment which is the waste of the presence of a healthy brain.</li>
                    <li className=' ml-6'>Many engaging alternatives to lecture come in short/quick and long formats. Examples include course discussions, having students read and resent, or appointing different student teachers each week.</li>
             
                   </ul>
                   <p className=' mb-3'>Choose engaging, minds-on alternatives to lectures and minimize lecturing or just talking at students.</p>
                   <Divider></Divider>
            </div>: null}
            {lectureIndex5 !== -1 ? <div className=' my-6'>
                <p className=' text-[20px] font-[700] text-[#2E3CC2] mb-2'>{last7ComponentNames[lectureIndex5].component_name}</p>
                <p className=''>Your score in {last7ComponentNames[lectureIndex5].component_name} indicates that {last7ComponentNames[lectureIndex5].average === 0 ? 
                ' you have little to no skill in this area': last7ComponentNames[lectureIndex5].average === 1 ?
                ' there is much room for improvement': last7ComponentNames[lectureIndex5].average === 2 ? ' there is room for improvement':
                last7ComponentNames[lectureIndex5].average === 3 ?' you have basic competence in this area ': last7ComponentNames[lectureIndex5].average === 4
                 ? ' you have mastery of this competency': null}. This skill involves teaching your students study skills and building their knowledge in the process of learning.
                   <br></br>It is important for 21st century learners to have awareness and skills in how they learn. 21st century learners will need to be lifelong learners because there is new material coming out at such a rapid pace.</p>
                   <ul className="list-disc">
                    <li className=' ml-6'>Demonstrate the learning behaviors you think will make them successful in your class.</li>
                    <li className=' ml-6'>Show them what you do (or did as a student) to achieve goals.</li>
                    <li className=' ml-6'>Take time out to address the students’ lack of study skills.</li>
                    <li className=' ml-6'>You can describe step by step how you would go about completing an assignment if it had been assigned to you.</li>
             
                   </ul>
                   <p className=' mb-3'>Tell your learners what works and tell them why it works, if you know why.</p>
                   <Divider></Divider>
            </div>: null}
            {lectureIndex6 !== -1 ? <div className=' my-6'>
                <p className=' text-[20px] font-[700] text-[#2E3CC2] mb-2'>{last7ComponentNames[lectureIndex6].component_name}</p>
                <p className=''>Your score in {last7ComponentNames[lectureIndex6].component_name} indicates that {last7ComponentNames[lectureIndex6].average === 0 ? 
                ' you have little to no skill in this area': last7ComponentNames[lectureIndex6].average === 1 ?
                ' there is much room for improvement': last7ComponentNames[lectureIndex6].average === 2 ? ' there is room for improvement':
                last7ComponentNames[lectureIndex6].average === 3 ?' you have basic competence in this area ': last7ComponentNames[lectureIndex6].average === 4
                 ? ' you have mastery of this competency': null}. This skill involves giving the students some control of some part of the course or lesson.
                   <br></br></p>
                   <ul className="list-disc">
                    <li className=' ml-6'>Making learners a part of the decision-making process creates automatic buy-in to the learning process.</li>
                    <li className=' ml-6'>Having a sense of control over your environments and the events that happen to you is a strong human motivational factor.</li>
                    <li className=' ml-6'>Transparency in your goal sharing makes learners more likely to reach those goals.</li>
                    <li className=' ml-6'>Recognizing student autonomy is a form of respect and regard which increases the engagement of the learner.</li>
                    <li className=' ml-6'>Respecting learners often elicits respect in return, the mutual respect improves the learning environment.</li>
                   </ul>
                   <p className=' mb-3'></p>
                   <Divider></Divider>
            </div>: null}
            {lectureIndex7 !== -1 ? <div className=' my-6'>
                <p className=' text-[20px] font-[700] text-[#2E3CC2] mb-2'>{last7ComponentNames[lectureIndex7].component_name}</p>
                <p className=''>Your score in {last7ComponentNames[lectureIndex7].component_name} indicates that {last7ComponentNames[lectureIndex7].average === 0 ? 
                ' you have little to no skill in this area': last7ComponentNames[lectureIndex7].average === 1 ?
                ' there is much room for improvement': last7ComponentNames[lectureIndex7].average === 2 ? ' there is room for improvement':
                last7ComponentNames[lectureIndex7].average === 3 ?' you have basic competence in this area ': last7ComponentNames[lectureIndex7].average === 4
                 ? ' you have mastery of this competency': null}. This skill involves emphasising relevance or usefulness of the course material to the students.
                   <br></br></p>
                   <ul className="list-disc">
                    <li className=' ml-6'>Knowing how something will be used makes learners effortlessly pay attention to it.</li>
                    <li className=' ml-6'>As your learner focuses on the use to them, they will automatically attach that focus to information you offer on the subject.</li>
                    <li className=' ml-6'>Emphasising usefulness to a specific individual or group is even more effective that stating general usefulness the mind automatically holds on to the things it has use for.</li>
                   </ul>
                   <p className=' mb-3'></p>
                   <Divider></Divider>
            </div>: null}
            {lectureIndex8 !== -1 ? <div className=' my-6'>
                <p className=' text-[20px] font-[700] text-[#2E3CC2] mb-2'>{last7ComponentNames[lectureIndex8].component_name}</p>
                <p className=''>Your score in {last7ComponentNames[lectureIndex8].component_name} indicates that {last7ComponentNames[lectureIndex8].average === 0 ? 
                ' you have little to no skill in this area': last7ComponentNames[lectureIndex8].average === 1 ?
                ' there is much room for improvement': last7ComponentNames[lectureIndex8].average === 2 ? ' there is room for improvement':
                last7ComponentNames[lectureIndex8].average === 3 ?' you have basic competence in this area ': last7ComponentNames[lectureIndex8].average === 4
                 ? ' you have mastery of this competency': null}. This skill involves maintaining a challenging but not too difficult or impossible level of difficulty in course work.
                   <br></br></p>
                   <ul className="list-disc">
                    <li className=' ml-6'>A good balance in difficulty level is critical to learner engagement.</li>
                    <li className=' ml-6'>If your course material is too difficult, learners will become disengaged because they don’t have hope.</li>
                    <li className=' ml-6'>If your course material is too easy, your learners will become disengaged because they lack challenge.</li>
                    <li className=' ml-6'>The proper balance provides both a reasonable challenge and the resulting reward of achievement.</li>
                    <li className=' ml-6'>Accomplishment, success, or the demonstration of ability is a strong human motivational driver.</li>
                   
                   </ul>
                   <p className=' mb-3'></p>
                   <Divider></Divider>
            </div>: null}
            {lectureIndex9 !== -1 ? <div className=' my-6'>
                <p className=' text-[20px] font-[700] text-[#2E3CC2] mb-2'>{last7ComponentNames[lectureIndex9].component_name}</p>
                <p className=''>Your score in {last7ComponentNames[lectureIndex9].component_name} indicates that {last7ComponentNames[lectureIndex9].average === 0 ? 
                ' you have little to no skill in this area': last7ComponentNames[lectureIndex9].average === 1 ?
                ' there is much room for improvement': last7ComponentNames[lectureIndex9].average === 2 ? ' there is room for improvement':
                last7ComponentNames[lectureIndex9].average === 3 ?' you have basic competence in this area ': last7ComponentNames[lectureIndex9].average === 4
                 ? ' you have mastery of this competency': null}. This skill involves creating natural interest or excitement within the course through the lesson design or delivery.
                   <br></br></p>
                   <ul className="list-disc">
                    <li className=' ml-6'>Interest is one of the easiest and most versatile dimensions within which we can create engagement in our learning environments.</li>
                    <li className=' ml-6'>The interest factor easily works even when students do not have an general interest in the course or are taking it for requirement reasons.</li>
                    <li className=' ml-6'>Interest factors elicit emotion that stimulates your learners and keeps them engaged and alert.</li>     
                    <li className=' ml-6'>It helps to plan out your interest interventions ahead of your course.</li>
                    <li className=' ml-6'>The following are elements that create interest and increase the energy
                        levels in your learning environment.
                        <br></br><p className=' italic'>Food, scandal, music, local nuance or references, celebrity, surprise, absurdity, games, competition. Popular culture, scandal, humour, dance, props, role-playing, multimedia, shapes and colors, sounds, videos and graphics.</p>
                        </li>
                   
                   </ul>
                   <p className=' mb-3'></p>
                   <Divider></Divider>
            </div>: null}
            {lectureIndex10 !== -1 ? <div className=' my-6'>
                <p className=' text-[20px] font-[700] text-[#2E3CC2] mb-2'>{last7ComponentNames[lectureIndex10].component_name}</p>
                <p className=''>Your score in {last7ComponentNames[lectureIndex10].component_name} indicates that {last7ComponentNames[lectureIndex10].average === 0 ? 
                ' you have little to no skill in this area': last7ComponentNames[lectureIndex10].average === 1 ?
                ' there is much room for improvement': last7ComponentNames[lectureIndex10].average === 2 ? ' there is room for improvement':
                last7ComponentNames[lectureIndex10].average === 3 ?' you have basic competence in this area ': last7ComponentNames[lectureIndex10].average === 4
                 ? ' you have mastery of this competency': null}. This skill involves showing your desire to see your students succeed, and giving some attention to that goal.
                   <br></br></p>
                   <ul className="list-disc">
                    <li className=' ml-6'>The caring element of human academic motivation is driven by two primary motivations:
                        <ol>
                            <li className=' ml-4'>a) Motivation of knowing someone cares about your achievement and is watching to see that you succeed.</li>
                            <li className=' ml-4'>b) Motivation from knowing your voice or opinion will be requested and heard in a peer group.</li>
                        </ol>
                    </li>
                    <li className=' ml-6'>Given these motivational factors educators should ensure that their learners know that they are tracking their performance.                                                                                                             </li>
                    <li className=' ml-6'>Educators should also create opportunities in most classes for students to share their ideas and opinions during learning activities.</li>
                    <li className=' ml-6'>Educators should give encouragement and feedback (individual or collective) on student performance indicating their expectations of the students.</li>
                   
                   </ul>
                   <p className=' mb-3'></p>
                   <Divider></Divider>
            </div>: null}
            
            {lectureIndex11 !== -1 ? <div className=' my-6'>
                <p className=' text-[20px] font-[700] text-[#2E3CC2] mb-2'>{last7ComponentNames[lectureIndex11].component_name}</p>
                <p className=''>Your score in {last7ComponentNames[lectureIndex11].component_name} indicates that {last7ComponentNames[lectureIndex11].average === 0 ? 
                ' you have little to no skill in this area': last7ComponentNames[lectureIndex11].average === 1 ?
                ' there is much room for improvement': last7ComponentNames[lectureIndex11].average === 2 ? ' there is room for improvement':
                last7ComponentNames[lectureIndex11].average === 3 ?' you have basic competence in this area ': last7ComponentNames[lectureIndex11].average === 4
                 ? ' you have mastery of this competency': null}. This skill involves requiring the students to meaningfully apply the course concepts to complete a task.
                   <br></br> Asking learners to meaningfully apply course concepts greatly helps them to own and remember information.</p>
                   <ul className="list-disc">
                    <li className=' ml-6'>The meanings that are engaged when the learners apply the concepts are exactly how they will remember them.</li>
                    <li className=' ml-6'>Meaningful application is one of the highest orders in encoding information.</li>
                    <li className=' ml-6'>By making it meaningful to the students you are forced to get into their brains and their frame.</li>
                   
                   
                   </ul>
                   <p className=' mb-3'></p>
                   <Divider></Divider>
            </div>: null}
            {lectureIndex12 !== -1 ? <div className=' my-6'>
                <p className=' text-[20px] font-[700] text-[#2E3CC2] mb-2'>{last7ComponentNames[lectureIndex12].component_name}</p>
                <p className=''>Your score in {last7ComponentNames[lectureIndex12].component_name} indicates that {last7ComponentNames[lectureIndex12].average === 0 ? 
                ' you have little to no skill in this area': last7ComponentNames[lectureIndex12].average === 1 ?
                ' there is much room for improvement': last7ComponentNames[lectureIndex12].average === 2 ? ' there is room for improvement':
                last7ComponentNames[lectureIndex12].average === 3 ?' you have basic competence in this area ': last7ComponentNames[lectureIndex12].average === 4
                 ? ' you have mastery of this competency': null}. This skill involves requiring the students to organise or reorganise items or concepts from the course content.
                   <br></br> The organization strategy uses the power of the human brain to create links and connections to make learners remember the information long after the lesson is over.</p>
                   <ul className="list-disc">
                    <li className=' ml-6'>Organisation works with the brain because the various groups have meanings associated with them.</li>
                    <li className=' ml-6'>When you use organisation the organisation criteria serve as anchors for students to own the material.</li>
                    <li className=' ml-6'>Having the students come up with their own organization criteria gives then another layer of meanings applied to the content.</li>
                   
                   </ul>
                   <p className=' mb-3'></p>
                   <Divider></Divider>
            </div>: null}
            {lectureIndex13 !== -1 ? <div className=' my-6'>
                <p className=' text-[20px] font-[700] text-[#2E3CC2] mb-2'>{last7ComponentNames[lectureIndex13].component_name}</p>
                <p className=''>Your score in {last7ComponentNames[lectureIndex13].component_name} indicates that {last7ComponentNames[lectureIndex13].average === 0 ? 
                ' you have little to no skill in this area': last7ComponentNames[lectureIndex13].average === 1 ?
                ' there is much room for improvement': last7ComponentNames[lectureIndex13].average === 2 ? ' there is room for improvement':
                last7ComponentNames[lectureIndex13].average === 3 ?' you have basic competence in this area ': last7ComponentNames[lectureIndex13].average === 4
                 ? ' you have mastery of this competency': null}. This skill involves showing students visual representations of course concepts.
                   <br></br> Visualising concepts is very effective in pulling concepts into your learner’s memory.</p>
                   <ul className="list-disc">
                    <li className=' ml-6'>The brain has a special place for pictures</li>
                    <li className=' ml-6'>Diagrams, photos, mind maps, stick figures, even simple shapes can serve as pictorial support for your lesson delivery.</li>
                    <li className=' ml-6'>The brain is able to recall pictorial information more easily than text.</li>
                    <li className=' ml-6'>The diagram or picture or shape does not even have to be well drawn to help students understand or visualise.</li>
                   </ul>
                   <p className=' mb-3'></p>
                   <Divider></Divider>
            </div>: null}
            {lectureIndex14 !== -1 ? <div className=' my-6'>
                <p className=' text-[20px] font-[700] text-[#2E3CC2] mb-2'>{last7ComponentNames[lectureIndex14].component_name}</p>
                <p className=''>Your score in {last7ComponentNames[lectureIndex14].component_name} indicates that {last7ComponentNames[lectureIndex14].average === 0 ? 
                ' you have little to no skill in this area': last7ComponentNames[lectureIndex14].average === 1 ?
                ' there is much room for improvement': last7ComponentNames[lectureIndex14].average === 2 ? ' there is room for improvement':
                last7ComponentNames[lectureIndex14].average === 3 ?' you have basic competence in this area ': last7ComponentNames[lectureIndex14].average === 4
                 ? ' you have mastery of this competency': null}. This skill involves using memory tricks or mnemonics of some sort in lesson activities.
                   <br></br>Mnemonic devices are things that we attach to a concept to help us to remember it.</p>
                   <ul className="list-disc">
                    <li className=' ml-6'>Examples are Every Good Boy Deserves Fanta (used to remember EGBDF in music).</li>
                    <li className=' ml-6'>Mnemonics associate the known to the unknown for easier recall.</li>
                    <li className=' ml-6'>Mnemonic devices should be well known and the associations strong/authentic.</li>
                   </ul>
                   <p className=' mb-3'></p>
                   <Divider></Divider>
            </div>: null}
            {lectureIndex15 !== -1 ?<div className=' my-6'>
                <p className=' text-[20px] font-[700] text-[#2E3CC2] mb-2'>{last7ComponentNames[lectureIndex15].component_name}</p>
                <p className=''>Your score in {last7ComponentNames[lectureIndex15].component_name} indicates that {last7ComponentNames[lectureIndex15].average === 0 ? 
                ' you have little to no skill in this area': last7ComponentNames[lectureIndex15].average === 1 ?
                ' there is much room for improvement': last7ComponentNames[lectureIndex15].average === 2 ? ' there is room for improvement':
                last7ComponentNames[lectureIndex15].average === 3 ?' you have basic competence in this area ': last7ComponentNames[lectureIndex15].average === 4
                 ? ' you have mastery of this competency': null}. This skill involves ensuring that completely new concepts to the students are presented to them at least 3 times during the lesson.
                   <br></br>One of the most basic ways the brain stores information in memory is through repetition.</p>
                   <ul className="list-disc">
                    <li className=' ml-6'>Repetition is the lowest order in encoding information.</li>
                    <li className=' ml-6'>For concepts that are completely new repetition is often useful for familiarity and encoding.</li>
                    <li className=' ml-6'>Repetition is most effective if coupled with at least attempted prior recall of the concepts ahead of the repeated exposure.</li>
                   </ul>
                   <p className=' mb-3'></p>

                   <Divider></Divider>
            </div>: null}
            
           
           
        </div>

    </div>
  )
}

export default ReportsDetials